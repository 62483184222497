import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import semver from 'semver';
import Config from './Config';
import State from './State';
import Log from './Log';
import flatten, { unflatten } from 'flat';
import moment from 'moment';
import HeartSeat from '../HeartSeatInterface';
import RecordingDialog from './RecordingDialog';
import FactoryResetDialog from './FactoryResetDialog';

const hsi = new HeartSeat();
const defaultHsConfig = {
  wifi_ssid: "",
  wifi_pass: "",
  cloud_endpoint: "",
}
const REC_START_MSG = "Begin Recording";

const WIFI_START_MSG = "Save Wifi Config";
const WIFI_SUCCESS_MSG = "Wifi Connected";
const WIFI_FAIL_MSG = "Connection Failed";

const CHECKIN_START_MSG = "Force Checkin";
const CHECKIN_SUCCESS_MSG = "Checkin Successful";
const CHECKIN_FAIL_MSG = "Checkin Failed";

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recDialogOpen: false,
      factoryResetDialogOpen: false,
      hsConnected: hsi.getConnectionState(),
      hsConfig: { ...defaultHsConfig },
      hsRecordingLabel: REC_START_MSG,
      hsWifiLabel: WIFI_START_MSG,
      hsCheckinLabel: CHECKIN_START_MSG,
      hsConfigChanges: {},
      hsState: {},
      hsLog: [],
      intervalId: null,
      recording_wait: 0,
      disableButtons: false,
    };

    this.onDebugMsg = this.bindWithErrors(this.onDebugMsg, this);
    this.onEvent = this.bindWithErrors(this.onEvent, this);
    this.onConnectionEvent = this.bindWithErrors(this.onConnectionEvent, this);
    this.onConnect = this.bindWithErrors(this.onConnect, this);
    this.loadState = this.bindWithErrors(this.loadState, this);
    this.onRefreshState = this.bindWithErrors(this.onRefreshState, this);
    this.onSaveWifiConfig = this.bindWithErrors(this.onSaveWifiConfig, this);
    this.onRecordingSubmit = this.bindWithErrors(this.onRecordingSubmit, this);
    this.onRecordingCancelled = this.bindWithErrors(this.onRecordingCancelled, this);
    this.onRecordButton = this.bindWithErrors(this.onRecordButton, this);
    this.onForceCheckin = this.bindWithErrors(this.onForceCheckin, this);
    this.onSoftwareReset = this.bindWithErrors(this.onSoftwareReset, this);
    this.onFactoryResetCancelled = this.bindWithErrors(this.onFactoryResetCancelled, this);
    this.onFactoryResetButton = this.bindWithErrors(this.onFactoryResetButton, this);
    this.onFactoryReset = this.bindWithErrors(this.onFactoryReset, this);
    this.onConfigChanged = this.bindWithErrors(this.onConfigChanged, this);
    this.onUpdateRecordingInterval = this.bindWithErrors(this.onUpdateRecordingInterval, this);
  }

  bindWithErrors(origCb, ctx) {
    const cb = origCb.bind(ctx);
    let fn = async function (...args) {
      try {
        await cb(...args);
      } catch (err) {
        ctx.props.onError(err)
      }
    }

    return fn.bind(ctx);
  }

  onDebugMsg(level, msg) {
    this.setState((state) => ({
      hsLog: [...state.hsLog, { level, msg, timestamp: moment().format('YYYY/MM/DD - HH:MM:SS') }],
    }));
  }

  onEvent(event) {
    /* events are unused at the moment */
  }

  onConnectionEvent(newConnectionState) {
    this.setState({ hsConnected: newConnectionState });
  }

  async onConnect() {
    const connectionState = hsi.getConnectionState();
    this.props.onErrorClose();

    if (connectionState === "disconnected") {
      await hsi.connect(this.onDebugMsg, this.onEvent, this.onConnectionEvent)
    } else if (connectionState === "connected") {
      hsi.disconnect();
    }

    if (hsi.getConnectionState() === "connected")
      await this.onRefreshState();
  }

  async loadState() {
    const state = await hsi.handleCmd('get_status', null);
    this.setState({ hsState: flatten(state, { safe: true }) });
  }

  async loadConfig() {
    try {
      const config_buf = await hsi.getFileData('user_cfg');
      const config = JSON.parse(config_buf.toString());
      this.setState({ hsConfig: flatten(config, { safe: true }) });
    } catch (err) {
      this.onDebugMsg('RESULT', 'error fetching user config');
    }
  }

  async onRefreshState() {
    await this.loadState();
    await this.loadConfig();
  }

  async onSaveWifiConfig() {
    let newConfig = { ...this.state.hsConfig };
    for (const key in this.state.hsConfigChanges) {
      newConfig[key] = this.state.hsConfigChanges[key];
    }
    await hsi.handleCmd('set_user_config', unflatten(newConfig, { safe: true }));
    await this.loadConfig();
    this.setState({ hsConfigChanges: {} });
    await this.onForceCheckin();
    if (this.state.hsCheckinLabel === CHECKIN_SUCCESS_MSG) {
      this.setState({ hsWifiLabel: WIFI_SUCCESS_MSG });
    } else {
      this.setState({ hsWifiLabel: WIFI_FAIL_MSG });
      throw new Error("Wifi Connection Failed!");
    }
  }

  async onRecordingSubmit(recConfig) {
    this.setState({ recDialogOpen: false });
    await hsi.handleCmd('create_recording', recConfig);
    this.onDebugMsg('RESULT', 'recording requested: ' + JSON.stringify(recConfig));
    this.setState({ hsRecordingLabel: 'rec: starting...', recording_wait: 5 });
  }

  onRecordingCancelled() {
    this.setState({ recDialogOpen: false });
  }

  async onRecordButton() {
    if (this.state.hsRecordingLabel !== REC_START_MSG) {
      await hsi.handleCmd('stop_recording', null);
      this.onDebugMsg('RESULT', 'stop requested');
      this.setState({ hsRecordingLabel: 'rec: stopping...' });
    } else {
      this.setState({ recDialogOpen: true });
    }
  }

  async onForceCheckin() {
    await this.onRefreshState();
    let oldCheckin = this.state.hsState.timeOfLastCheckin;
    await hsi.handleCmd('force_cloud_check_in', null);
    this.onDebugMsg('RESULT', 'checkin requested');
    for (let i = 0; i < 10; i++) {
      await new Promise(r => setTimeout(r, 1000));
      await this.onRefreshState();
      if (oldCheckin !== this.state.hsState.timeOfLastCheckin) {
        this.setState({ hsCheckinLabel: CHECKIN_SUCCESS_MSG });
        return;
      }
    }
    this.setState({ hsCheckinLabel: CHECKIN_FAIL_MSG });
  }

  async onSoftwareReset() {
    await hsi.handleCmd('software_reset', null);
    this.onDebugMsg('RESULT', 'software reset requested');
    await new Promise(r => setTimeout(r, 1000));
    await this.onRefreshState();
  }

  onFactoryResetCancelled() {
    this.setState({ factoryResetDialogOpen: false });
  }

  onFactoryResetButton() {
    this.setState({ factoryResetDialogOpen: true });
  }

  async onFactoryReset() {
    await hsi.handleCmd('factory_reset', null);
    this.setState({ factoryResetDialogOpen: false });
    this.onDebugMsg('RESULT', 'factory reset requested');
    await new Promise(r => setTimeout(r, 1000));
    this.setState({ hsConfig: flatten({ ...defaultHsConfig }, { safe: true }) });
    await this.onRefreshState();
  }

  onConfigChanged(key, value) {
    const newConfigChanges = { ...this.state.hsConfigChanges };

    if (value === "") {
      delete newConfigChanges[key];
    } else if (typeof this.state.hsConfig[key] === 'boolean') {
      newConfigChanges[key] = ((value !== 'false') && (value !== '0') && (value !== 'f')) ? true : false;
    } else if (typeof this.state.hsConfig[key] === 'number') {
      newConfigChanges[key] = parseFloat(value);
    } else {
      newConfigChanges[key] = value;
    }

    this.setState({ hsConfigChanges: newConfigChanges });
  }

  componentDidMount() {
    const intervalId = setInterval(this.onUpdateRecordingInterval, 2000);
    /* store intervalId in the state so it can be accessed later: */
    this.setState({ intervalId: intervalId });
  }
  componentWillUnmount() {
    /* use intervalId from the state to clear the interval */
    clearInterval(this.state.intervalId);
  }

  async onUpdateRecordingInterval() {
    try {
      if (this.state.hsConnected === "connected") {
        const status = await hsi.handleCmd('get_recording_status', null);
        if (status["isRecording"]) {
          this.setState({ hsRecordingLabel: 'rec: ' + status["lengthSeconds"] + " (stop)", recording_wait: 0 });
        } else if ((this.state.hsRecordingLabel !== REC_START_MSG) && (this.state.hsRecordingLabel !== "rec: 0 (stop)")) {
          if (this.state.hsRecordingLabel === 'rec: starting...') {
            if (this.state.recording_wait !== 0) {
              this.state.recording_wait = this.state.recording_wait - 1;
            }
            else {
              this.setState({ hsRecordingLabel: REC_START_MSG });
            }
          }
          else {
            this.setState({ hsRecordingLabel: REC_START_MSG });
          }
        }
      }
    } catch (err) {
      /* ignore any errors in here. this ui update is optional. */
    }
  }

  async onClickButton(funcCall) {
    this.setState({ disableButtons: true });
    await funcCall();
    this.setState({ disableButtons: false });
  }

  render() {
    return (
      <div>
        <RecordingDialog
          open={this.state.recDialogOpen}
          onClose={this.onRecordingCancelled}
          onSubmit={this.onRecordingSubmit}
        />
        <FactoryResetDialog
          open={this.state.factoryResetDialogOpen}
          onClose={this.onFactoryResetCancelled}
          onSubmit={this.onFactoryReset}
        />
        <Grid container direction="column" spacing={2}>
          {(this.state.hsState.firmwareVersion && semver.lt(this.state.hsState.firmwareVersion, '0.12.0')) ?
            <Grid item>
              <Alert variant="filled" severity="warning">
                <AlertTitle>Version Incompatibility Detected</AlertTitle>
                Some functionality may not work. Please try using <a href="https://ble.casanacare.com">https://ble.casanacare.com</a>
              </Alert>
            </Grid>
            : null
          }
          <Grid item>
            <Grid container direction="row" justify="center" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color={(this.state.hsConnected === "disconnected") ? "primary" : "secondary"}
                  onClick={async () => { await this.onClickButton(this.onConnect); }}>
                  {this.state.hsConnected}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={this.state.disableButtons || this.state.hsConnected !== "connected"}
                  variant="contained"
                  color="primary"
                  onClick={async () => { await this.onClickButton(this.onRefreshState); }}>
                  Refresh State
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={this.state.disableButtons || this.state.hsRecordingLabel !== REC_START_MSG || this.state.hsConnected !== "connected"}
                  variant="contained"
                  color={(this.state.hsWifiLabel !== WIFI_FAIL_MSG) ? "primary" : "secondary"}
                  onClick={async () => {
                    await this.onClickButton(this.onSaveWifiConfig);
                    await new Promise(r => setTimeout(r, 5000));
                    this.setState({ hsWifiLabel: WIFI_START_MSG, hsCheckinLabel: CHECKIN_START_MSG });
                  }}>
                  {this.state.hsWifiLabel}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={this.state.disableButtons || this.state.hsConnected !== "connected"}
                  variant="contained"
                  color={(this.state.hsRecordingLabel === REC_START_MSG) ? "primary" : "secondary"}
                  onClick={async () => { await this.onClickButton(this.onRecordButton); }}>
                  {this.state.hsRecordingLabel}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={this.state.disableButtons || this.state.hsRecordingLabel !== REC_START_MSG || this.state.hsConnected !== "connected"}
                  variant="contained"
                  color={(this.state.hsCheckinLabel !== CHECKIN_FAIL_MSG) ? "primary" : "secondary"}
                  onClick={async () => {
                    await this.onClickButton(this.onForceCheckin);
                    await new Promise(r => setTimeout(r, 5000));
                    this.setState({ hsCheckinLabel: CHECKIN_START_MSG });
                  }}>
                  {this.state.hsCheckinLabel}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={this.state.hsConnected !== "connected"}
                  variant="contained"
                  color="primary"
                  onClick={async () => { await this.onClickButton(this.onSoftwareReset); }}>
                  Software Reset
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={this.state.hsConnected !== "connected"}
                  variant="contained"
                  color="secondary"
                  onClick={async () => { await this.onClickButton(this.onFactoryResetButton); }}>
                  Factory Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <State state={this.state.hsState}></State>
          </Grid>
          <Grid item>
            <Config
              config={this.state.hsConfig}
              configChanges={this.state.hsConfigChanges}
              onConfigChanged={this.onConfigChanged} />
          </Grid>
          <Grid item>
            <Log logEntries={this.state.hsLog}></Log>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Main;
