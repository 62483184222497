import * as rs from 'restructure';
import { crc32 } from 'js-crc'
import * as concat from 'concat-stream';
import * as proto from './protobuf/recording_pb';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class ParamDef {
  async unpack(buf) {
    throw new Error("not implemented");
  }

  async pack(unpackedArg) {
    throw new Error("not implemented");
  }

  print(unpackedArg) {
    throw new Error("not implemented");
  }
}

class NoneDef extends ParamDef {
  async unpack(buf) {
    return null;
  }

  async pack(unpackedArg) {
    if (unpackedArg)
      throw new Error("unexpected argument");

    return Buffer.from([]);
  }

  print(unpackedArg) {
    return "";
  }
}

class BytesDef extends ParamDef {
  async unpack(buf) {
    return buf;
  }

  async pack(unpackedArg) {
    return Buffer.from(unpackedArg);
  }

  print(unpackedArg) {
    return unpackedArg.toString();
  }
}
class StringDef extends ParamDef {
  async unpack(buf) {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(buf);
  }

  async pack(unpackedArg) {
    const encoder = new TextEncoder('utf-8');
    return Buffer.from([...encoder.encode(unpackedArg), '\0']);
  }

  print(unpackedArg) {
    return unpackedArg.toString();
  }
}

class JSONDef extends ParamDef {
  async unpack(buf) {
    const decoder = new TextDecoder('utf-8');
    return JSON.parse(decoder.decode(buf));
  }

  async pack(unpackedArg) {
    const encoder = new TextEncoder('utf-8');
    return Buffer.from([...encoder.encode(JSON.stringify(unpackedArg)), '\0']);
  }

  print(unpackedArg) {
    return JSON.stringify(unpackedArg, null, 4);
  }
}

class StructDef extends ParamDef {
  constructor(schema) {
    super();
    this.schema = schema;
  }

  size() {
    return this.schema.size();
  }

  async unpack(buf) {
    return this.schema.decode(new rs.DecodeStream(buf));
  }

  structEncode(props) {
    const encStream = new rs.EncodeStream();
    const promise = new Promise(function (resolve, reject) {
      encStream.pipe(concat(function (buf) {
        resolve(buf);
      }));
      encStream.on('error', function (err) {
        reject(err);
      });
    });

    this.schema.encode(encStream, props);
    encStream.end();
    return promise;
  }

  async pack(unpackedArg) {
    let argCopy = {};

    for (const key in unpackedArg) {
      if (typeof unpackedArg[key] === 'string') {
        argCopy[key] = Buffer.alloc(this.schema.fields[key].length);
        argCopy[key].write(unpackedArg[key], 'utf-8');
      } else {
        argCopy[key] = unpackedArg[key];
      }
    }

    return await this.structEncode(argCopy);
  }

  print(unpackedArg) {
    return JSON.stringify(unpackedArg, null, 4);
  }
}
class ProtobufDef extends ParamDef {
  constructor(schema) {
    super();
    this.schema = schema;
  }

  async unpack(buf) {
    return this.schema.deserializeBinary(buf).toObject();
  }

  async pack(unpackedArg) {
    /* no commands currently take protobufs as input parameters */
    throw new Error("protobuf packing");
  }

  print(unpackedArg) {
    return JSON.stringify(unpackedArg, null, 4);
  }
}

const BLE_SERVICE_UUID = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
const BLE_TX_CHARACTERISTIC_UUID = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
const BLE_RX_CHARACTERISTIC_UUID = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';

const COMMAND_TYPES = {
  'get_fw_version': {
    'cmd_id': 1,
    'req_fmt': new NoneDef(),
    'res_fmt': new StringDef(),
    'help': 'Get the current firmware version',
  },
  'echo': {
    'cmd_id': 2,
    'req_fmt': new StructDef(new rs.Struct({ param_a: rs.uint16le, param_b: rs.uint16le })),
    'res_fmt': new StructDef(new rs.Struct({ param_a: rs.uint16le, param_b: rs.uint16le })),
    'help': 'Get the current firmware version with test params',
  },
  'fw_update_begin': {
    'cmd_id': 3,
    'req_fmt': new NoneDef(),
    'res_fmt': new NoneDef(),
    'help': 'Begin a firmware update',
  },
  'fw_file_header': {
    'cmd_id': 4,
    'req_fmt': new StructDef(new rs.Struct({ mod_id: rs.uint32le, fsize: rs.uint32le })),
    'res_fmt': new NoneDef(),
    'help': 'Send a firmware update file header',
  },
  'fw_file_data': {
    'cmd_id': 5,
    'req_fmt': new BytesDef(),
    'res_fmt': new NoneDef(),
    'help': 'Send a firmware update file data',
  },
  'fw_update_status': {
    'cmd_id': 6,
    'req_fmt': new NoneDef(),
    'res_fmt': new StructDef(new rs.Struct({ is_running: rs.uint16le, error: rs.uint16le })),
    'help': 'Get the status of a firmware update',
  },
  'stay_awake': {
    'cmd_id': 7,
    'req_fmt': new StructDef(new rs.Struct({ seconds: rs.uint16le })),
    'res_fmt': new NoneDef(),
    'help': 'Tell the firmware to stay awake',
  },
  'checkin': {
    'cmd_id': 8,
    'req_fmt': new NoneDef(),
    'res_fmt': new NoneDef(),
    'help': 'Check in with the cloud',
  },
  'factory_reset': {
    'cmd_id': 9,
    'req_fmt': new NoneDef(),
    'res_fmt': new NoneDef(),
    'no_response': true,
    'help': 'Performs a factory reset of the seat',
  },
  'software_reset': {
    'cmd_id': 10,
    'req_fmt': new NoneDef(),
    'res_fmt': new NoneDef(),
    'no_response': true,
    'help': 'Performs software reset of the MCU',
  },
  'create_recording': {
    'cmd_id': 11,
    'req_fmt': new StructDef(new rs.Struct({ reason: rs.uint16le, duration: rs.uint16le })),
    'res_fmt': new NoneDef(),
    'help': 'Creates a recording with reason={0-4} and duration={seconds}',
  },
  'force_cloud_check_in': {
    'cmd_id': 12,
    'req_fmt': new NoneDef(),
    'res_fmt': new NoneDef(),
    'help': 'Force the seat to check in with the cloud',
  },
  'old_get_config': {
    'cmd_id': 13,
    'req_fmt': new NoneDef(),
    'res_fmt': new NoneDef(),
    'help': 'Depricated in favor of reading config files directly',
  },
  'old_set_config': {
    'cmd_id': 14,
    'req_fmt': new NoneDef(),
    'res_fmt': new NoneDef(),
    'help': 'Depricated in favor of set_user_config and cloud-based operating config',
  },
  'get_status': {
    'cmd_id': 15,
    'req_fmt': new NoneDef(),
    'res_fmt': new ProtobufDef(proto.HeartSeatStatus),
    'help': 'fetches state on the Heart Seat',
  },
  'file_get_info': {
    'cmd_id': 16,
    'req_fmt': new StringDef(),
    'res_fmt': new StructDef(new rs.Struct({ fsize: rs.int32le, max_chunk_size: rs.uint32le })),
    'help': 'Get the size of a file along with the max chunk size',
  },
  'file_read_raw': {
    'cmd_id': 17,
    'req_fmt': new StructDef(new rs.Struct({ path: new rs.Buffer(64), index: rs.uint32le, bytes: rs.uint32le })),
    'res_fmt': new BytesDef(),
    'help': 'Reads the actual data from the file one chunk at a time',
  },
  'stop_recording': {
    'cmd_id': 18,
    'req_fmt': new NoneDef(),
    'res_fmt': new NoneDef(),
    'help': 'Stops an active recording',
  },
  'get_recording_status': {
    'cmd_id': 19,
    'req_fmt': new NoneDef(),
    'res_fmt': new ProtobufDef(proto.RecordingStatus),
    'help': 'Get the recording transport status',
  },
  'clear_recordings': {
    'cmd_id': 20,
    'req_fmt': new NoneDef(),
    'res_fmt': new StringDef(),
    'help': 'Delete all recordings saved locally on the seat',
  },
  'dump_profile': {
    'cmd_id': 21,
    'req_fmt': new NoneDef(),
    'res_fmt': new NoneDef(),
    'help': 'Display function profiling log and reset profiling statistics',
  },
  'clear_logs': {
    'cmd_id': 22,
    'req_fmt': new NoneDef(),
    'res_fmt': new NoneDef(),
    'help': 'Clear logs and start a new one',
  },
  'set_ppg_on': {
    'cmd_id': 23,
    'req_fmt': new NoneDef(),
    'res_fmt': new StringDef(),
    'help': 'Set PPG power on until next reset.',
  },
  'set_user_config': {
    'cmd_id': 24,
    'req_fmt': new JSONDef(),
    'res_fmt': new NoneDef(),
    'help': 'Set the user config',
  },
  'set_next_led_currents': {
    'cmd_id': 25,
    'req_fmt': new StructDef(new rs.Struct({ red_current_mA: rs.float, ir_current_mA: rs.float })),
    'res_fmt': new NoneDef(),
    'help': 'Set the LED currents (red_current_mA and ir_current_mA) for the next recording',
  },
};

export default class HeartSeat {
  constructor() {
    this.init();
  }

  init() {
    this.BLE_FRAME_DEF = new StructDef(new rs.Struct({ magic: rs.uint16le, len: rs.uint16le }));
    this.MSG_HEADER_DEF = new StructDef(new rs.Struct({ magic: rs.uint16le, len: rs.uint16le, type_magic: rs.uint16le, pad: rs.uint16le }));
    this.MSG_FOOTER_DEF = new StructDef(new rs.Struct({ magic: rs.uint16le, pad: rs.uint16le, crc: rs.uint32le }));
    this.DBG_HEADER_DEF = new StructDef(new rs.Struct({ level: rs.uint16le, len: rs.uint16le }));
    this.CMD_HEADER_DEF = new StructDef(new rs.Struct({ cmd: rs.uint16le, seq: rs.uint16le, len: rs.uint16le, pad: rs.uint16le }));
    this.RSP_HEADER_DEF = new StructDef(new rs.Struct({ cmd: rs.uint16le, seq: rs.uint16le, len: rs.uint16le, error: rs.uint16le }));
    this.BLE_FRAME_MAGIC = 0x4c42 /* 'BL' */
    this.MSG_HEADER_MAGIC = 0x534d /* 'MS' */
    this.MSG_FOOTER_MAGIC = 0x4d53 /* 'SM' */
    this.CMD_MAGIC = 0x4e46 /* 'FN' */
    this.RSP_MAGIC = 0x5352 /* 'RS' */
    this.EVT_MAGIC = 0x5645 /* 'EV' */
    this.DBG_MAGIC = 0x4244 /* 'DB' */

    this.MSG_TYPES = {}
    /* dictionaries can't have numerical keys, unlike in python */
    this.MSG_TYPES[`${this.EVT_MAGIC}`] = {
      'name': 'EVENT',
      'handleFn': this.onEventMsg,
    }
    this.MSG_TYPES[`${this.RSP_MAGIC}`] = {
      'name': 'RESPONSE',
      'handleFn': this.onResponseMsg,
    }
    this.MSG_TYPES[`${this.DBG_MAGIC}`] = {
      'name': 'DEBUG',
      'handleFn': this.onDebugMsg,
    }

    this.commandTypes = COMMAND_TYPES;
    this.bluetooth = null;
    this.gattServer = null;
    this.primaryService = null;
    this.txCharacteristic = null;
    this.rxCharacteristic = null;
    this.cmdPromise = {};
    this.seqid = 0;
    this.timeoutMs = 10000;
    this.onMsgErrorCb = null;
    this.onDebugCb = null;
    this.onEventCb = null;
    this.connecting = false;
    this.currentBuf = Buffer.from([]);
    this.sendQueue = [];
    this.handleRxEvent.bind(this);
  }

  async onEventMsg(buf) {
    const decoder = new TextDecoder('utf-8');
    const msgStr = decoder.decode(buf);
    const obj = JSON.parse(msgStr)

    if (this.onEventCb)
      this.onEventCb(obj);
  }

  debugLevelName(level) {
    switch (level) {
      case 0: return "DEBUG";
      case 1: return "INFO ";
      case 2: return "WARN ";
      case 3: return "ERROR";
      case 4: return "PANIC";
      default: return "UNKNO";
    }
  }

  async onDebugMsg(buf) {
    const headerSize = this.DBG_HEADER_DEF.size();
    if (buf.length < headerSize)
      throw new Error("invalid debug message length");

    const headerBuf = buf.slice(0, headerSize);
    const headerDict = await this.DBG_HEADER_DEF.unpack(headerBuf);
    const decoder = new TextDecoder('utf-8');
    const msg = decoder.decode(buf.slice(headerSize));

    if (this.onDebugCb)
      this.onDebugCb(this.debugLevelName(headerDict['level']), msg);
  }

  async onResponseMsg(buf) {
    let cmdSeqId = null;
    try {
      const headerSize = this.RSP_HEADER_DEF.size();

      if (buf.length < headerSize)
        throw new Error("invalid response length");

      const headerBuf = buf.slice(0, headerSize);
      const headerDict = await this.RSP_HEADER_DEF.unpack(headerBuf);

      if (buf.length < headerDict['len'])
        throw new Error('message buffer shorter than specified length')

      cmdSeqId = headerDict['seq'];
      const bodyBuf = buf.slice(headerSize);

      const cmdId = headerDict['cmd'];
      if (cmdId === 0 || cmdId >= this.commandTypes.length + 1)
        throw new Error('invalid response command id');

      let desc = null;
      for (const cmdName in this.commandTypes) {
        if (this.commandTypes[cmdName]['cmd_id'] === cmdId) {
          desc = this.commandTypes[cmdName];
          break;
        }
      }

      if (desc === null)
        throw new Error('unknown response command id');

      if (headerDict['error'] !== 0) {
        const decoder = new TextDecoder('utf-8');
        const msg = decoder.decode(bodyBuf).replace(/\0[\s\S]*$/g, '');
        throw new Error(`command failed with error code ${headerDict['error']}: ${msg}`);
      }

      if (this.cmdPromise[cmdSeqId])
        this.cmdPromise[cmdSeqId].resolve(bodyBuf);
    } catch (err) {
      if ((cmdSeqId !== null) && this.cmdPromise[cmdSeqId])
        this.cmdPromise[cmdSeqId].resolve(err); /* an error is a valid response, so we "resolve" it */
    }
  }

  async parseBuf(buf) {
    let bytesHandled = 0;

    if (buf.length < 2)
      return bytesHandled;

    try {
      const magicStruct = new StructDef(new rs.Struct({ magic: rs.uint16le }));
      const magicDict = await magicStruct.unpack(buf.slice(0, 2));
      const magic = magicDict['magic'];
      if (magic !== this.MSG_HEADER_MAGIC)
        throw new Error(`invalid message magic ${magic}`);

      const headerSize = this.MSG_HEADER_DEF.size();
      const footerSize = this.MSG_FOOTER_DEF.size();

      if (buf.length < headerSize + footerSize)
        return 0;

      const headerBuf = buf.slice(0, headerSize);
      const headerDict = await this.MSG_HEADER_DEF.unpack(headerBuf);

      if (buf.length < headerDict['len'])
        return 0;

      const footerBuf = buf.slice(buf.length - footerSize);
      const footerDict = await this.MSG_FOOTER_DEF.unpack(footerBuf);

      const msgContents = buf.slice(headerSize, buf.length - footerSize);

      const crc = Number('0x' + crc32(buf.slice(0, buf.length - 4)));
      if (footerDict['crc'] !== crc) {
        bytesHandled = buf.length;
        throw new Error('invalid response checksum');
      }

      const typeMagic = headerDict['type_magic'];
      const msgCallbackDesc = this.MSG_TYPES[`${typeMagic}`];
      if (!msgCallbackDesc) {
        bytesHandled = buf.length;
        throw new Error(`invalid type_magic ${typeMagic}`);
      }

      await msgCallbackDesc['handleFn'].call(this, msgContents);

      bytesHandled = buf.length;
      return bytesHandled;
    } catch (err) {
      if (this.onMsgErrorCb)
        this.onMsgErrorCb(err);

      bytesHandled = buf.length;
      return bytesHandled;
    }
  }

  async handleRxEvent(event) {
    const hsi = this.service.device.__hsi; /* this function is executed by the BluetoothCharacteristic */
    const newBuf = Buffer.from(event.target.value.buffer);
    const buf = Buffer.from([...hsi.currentBuf, ...newBuf]);

    try {
      const bytesHandled = await hsi.parseBuf(buf);

      if (bytesHandled === buf.length) {
        hsi.currentBuf = Buffer.from([]);
        if (hsi.responseWaiter) {
          clearTimeout(hsi.responseWaiter);
          hsi.responseWaiter = null;
        }
      } else {
        hsi.currentBuf = buf.slice(bytesHandled);
        if (hsi.responseWaiter) {
          clearTimeout(hsi.responseWaiter);
          hsi.responseWaiter = null;
        }
        hsi.responseWaiter = setTimeout(() => {
          if (hsi.onMsgErrorCb)
            hsi.onMsgErrorCb(new Error('timed out waiting for response'));

          hsi.currentBuf = Buffer.from([]);
          hsi.responseWaiter = null;
        }, 3000);
      }
    } catch (err) {
      if (hsi.onMsgErrorCb)
        hsi.onMsgErrorCb(err);

      hsi.currentBuf = Buffer.from([]);
      if (hsi.responseWaiter) {
        clearTimeout(hsi.responseWaiter);
        hsi.responseWaiter = null;
      }
    }
  }

  getSeqId() {
    this.seqid = (this.seqid + 1) & 0xffff;
    return this.seqid;
  }

  async wrapBleFrame(buf) {
    const bleHeader = await this.BLE_FRAME_DEF.pack({ magic: this.BLE_FRAME_MAGIC, len: buf.length });
    return Buffer.from([...bleHeader, ...buf]);
  }

  async wrapMessage(typeMagic, buf) {
    const msgLen = this.MSG_HEADER_DEF.size() + buf.length + this.MSG_FOOTER_DEF.size();

    const msgHeaderDict = {
      magic: this.MSG_HEADER_MAGIC,
      len: msgLen,
      type_magic: typeMagic,
      pad: 0,
    }
    const headerBuf = await this.MSG_HEADER_DEF.pack(msgHeaderDict);

    const footerMagicStruct = new StructDef(new rs.Struct({ magic: rs.uint16le, pad: rs.uint16le }));
    const magicBytes = await footerMagicStruct.pack({ magic: this.MSG_FOOTER_MAGIC, pad: 0 });
    const combinedBuf = Buffer.from([...headerBuf, ...buf, ...magicBytes]);
    const crc = Number('0x' + crc32(combinedBuf))

    const footerDict = {
      magic: this.MSG_FOOTER_MAGIC,
      pad: 0,
      crc: crc,
    }
    const footerBuf = await this.MSG_FOOTER_DEF.pack(footerDict);

    const msgBuf = Buffer.from([...headerBuf, ...buf, ...footerBuf]);
    return msgBuf;
  }

  createResponsePromise(timeoutMs) {
    let res, rej;
    let timeoutHandle;

    const timeoutPromise = new Promise((resolve, reject) => {
      timeoutHandle = setTimeout(function () {
        reject(new Error('response timed out'));
      }, timeoutMs);
    });

    const responsePromise = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    });

    let promise = Promise.race([
      responsePromise,
      timeoutPromise,
    ]).then((result) => {
      clearTimeout(timeoutHandle);
      return result;
    });

    promise.resolve = res;
    promise.reject = rej;
    return promise;
  }

  async sendBuf(buf) {
    this.sendQueue.push(buf);
    if (this.sendQueue.length === 1) {
      /* send contents of queue */
      while (this.sendQueue.length !== 0) {
        let currentBuff = this.sendQueue[0];
        for (let i = 0; i < currentBuff.length; i += 20) {
          await sleep(100);
          try {
            await this.txCharacteristic.writeValue(currentBuff.slice(i, i + 20));
          } catch (error) {
            i -= 20;
          }
        }
        this.sendQueue.shift();
      }
    }
  }

  async handleCmd(cmdName, params) {
    let cmdSeqId = null;
    try {
      if (this.getConnectionState() !== 'connected')
        throw new Error('not connected');

      const desc = this.commandTypes[cmdName];
      if (!desc)
        throw new Error('invalid command name');

      const bodyBuf = await desc['req_fmt'].pack(params);

      cmdSeqId = this.getSeqId();
      const headerDict = {
        cmd: desc['cmd_id'],
        seq: cmdSeqId,
        len: this.CMD_HEADER_DEF.size() + bodyBuf.length,
        pad: 0,
      };
      const headerBuf = await this.CMD_HEADER_DEF.pack(headerDict);

      const cmdBuf = Buffer.from([...headerBuf, ...bodyBuf]);
      const msgBuf = await this.wrapMessage(this.CMD_MAGIC, cmdBuf)
      const bleBuf = await this.wrapBleFrame(msgBuf);

      if (desc['no_response']) {
        this.sendBuf(bleBuf);
        return null;
      }

      this.cmdPromise[cmdSeqId] = this.createResponsePromise(this.timeoutMs);
      this.sendBuf(bleBuf);
      const rawResponse = await this.cmdPromise[cmdSeqId];
      delete this.cmdPromise[cmdSeqId];

      if (rawResponse instanceof Error) {
        throw rawResponse;
      } else {
        return await desc['res_fmt'].unpack(rawResponse);
      }
    } finally {
      if (cmdSeqId !== null)
        delete this.cmdPromise[cmdSeqId];
    }
  }

  async getFileData(path) {
    const file_dict = await this.handleCmd('file_get_info', path);
    const fsize = file_dict['fsize'];
    const max_chunk_size = file_dict['max_chunk_size'];

    let index = 0;
    let file_data = Buffer.from([]);
    while (index < fsize) {
      const bytes = Math.min(fsize - index, max_chunk_size);
      const buf = await this.handleCmd('file_read_raw', { path, index, bytes });

      file_data = Buffer.from([...file_data, ...buf]);
      index += buf.length;
    }

    return file_data;
  }

  getConnectionState() {
    if (this.connecting)
      return "connecting";
    else if ((this.bluetooth !== null) && this.bluetooth.gatt.connected && this.primaryService)
      return "connected";
    else
      return "disconnected"
  }

  disconnect() {
    if (this.getConnectionState() === 'connected') {
      this.bluetooth.gatt.disconnect();
      this.onDebugCb = null;
      this.onDisconnected = null;
    }

    this.onConnectionEvent(this.getConnectionState());
  }

  async connect(onDebugCb, onEventCb, onConnectionEvent) {
    let attempts = 0;
    let bluetooth;

    this.init();

    this.onDebugCb = onDebugCb;
    this.onEventCb = onEventCb;
    this.onConnectionEvent = onConnectionEvent;

    try {
      bluetooth = await navigator.bluetooth.requestDevice({
        filters: [{ services: [BLE_SERVICE_UUID] }]
      });
    } catch (err) {
      /* user cancelled the picker */
      return;
    }

    this.connecting = true;
    this.onConnectionEvent('connecting');

    while (1) {
      try {
        attempts++;
        console.log('attempt ' + attempts);
        this.bluetooth = bluetooth;
        this.bluetooth.__hsi = this;
        this.bluetooth.addEventListener('gattserverdisconnected', () => this.onConnectionEvent(this.getConnectionState()));

        this.gattServer = await this.bluetooth.gatt.connect();
        this.primaryService = await this.gattServer.getPrimaryService(BLE_SERVICE_UUID);
        this.txCharacteristic = await this.primaryService.getCharacteristic(BLE_TX_CHARACTERISTIC_UUID);
        this.rxCharacteristic = await this.primaryService.getCharacteristic(BLE_RX_CHARACTERISTIC_UUID);
        this.rxCharacteristic.oncharacteristicvaluechanged = this.handleRxEvent;
        await this.rxCharacteristic.startNotifications();
        this.connecting = false;
        this.onConnectionEvent(this.getConnectionState());
        return;
      } catch (err) {
        if (attempts > 5) {
          this.bluetooth.gatt.disconnect();
          this.bluetooth = null;
          this.connecting = false;
          this.onConnectionEvent(this.getConnectionState());
          throw err;
        }
      }
    }
  }
}
