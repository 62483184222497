import { makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    boxSizing: 'border-box',
    padding: '16px',
  },
  hiddenField: {
    pointerEvents: 'none',
    visibility: 'hidden',
  }
}));

function State(props) {
  const styles = useStyles();

  return (
    <Paper className={styles.paper}>
      <Typography variant="h4" align="left">Status</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="30%" align="left">Name</TableCell>
            <TableCell align="left">Value</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(props.state).map(kv => (
            <TableRow key={kv[0]}>
              <TableCell>{kv[0]}</TableCell>
              <TableCell>{(typeof kv[1] === 'number' && kv[1] % 1 !== 0) ? kv[1].toFixed(2).toString() : kv[1].toString()}</TableCell>
              <TableCell>
                <TextField size="small" variant="outlined" className={styles.hiddenField}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default State;
