import { makeStyles, Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    boxSizing: 'border-box',
    padding: '16px',
  },
  logMessageContainer: {
    alignItems: 'flex-start',
    background: '#efefef',
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    maxHeight: "300px",
    overflow: "auto",
  },
  logMessage: {
    fontFamily: 'monospace',
    fontSize: '14px',
    lineHeight: '1.4',
  },
}));

function Log(props) {
  const styles = useStyles();

  if (props.logEntries.length === 0) {
    return (
      <Paper className={styles.paper}>
        <Typography variant="h4" align="left">Log</Typography>
      </Paper>
    );
  }

  return (
    <Paper className={styles.paper}>
      <Typography variant="h4" align="left">Log</Typography>
      <div className={styles.logMessageContainer}>
        {props.logEntries
          .map((entry, i) => (
            /*
             * This reverses the list which, when combined with flexDirection: column-reverse amd overflow: auto,
             * just has the effect of making the log auto-scroll to the bottom as exepcted.
             */
            props.logEntries[props.logEntries.length - 1 - i]
          ))
          .map((entry, i) => (
            <div key={i} className={styles.logMessage}>
              [{entry.level}] {entry.timestamp} | {entry.msg}
            </div>
          ))}
      </div>
    </Paper>
  );
}

export default Log;
