import React, { useState } from 'react';
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button, MenuItem } from "@material-ui/core";
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  textInput: {
    minWidth: '160px',
  },
}));

function RecordingDialog(props) {
  const styles = useStyles();
  const [errorMsg, setErrorMsg] = useState(null);
  const [recConfig, setRecConfig] = useState({ reason: "1", duration: "30" });

  function onRecordingReasonChanged(newReason) {
    const newConfig = { ...recConfig };
    newConfig.reason = newReason;
    setRecConfig(newConfig);
  }

  function onRecordingDurationChanged(newDuration) {
    const newConfig = { ...recConfig };
    newConfig.duration = newDuration;
    setRecConfig(newConfig);
  }

  function onSubmit() {
    let duration, reason;

    try {
      reason = parseInt(recConfig.reason);
    } catch (err) {
      setErrorMsg("reason is not an integer");
      return;
    }

    try {
      duration = parseInt(recConfig.duration);
    } catch (err) {
      setErrorMsg("duration is not an integer");
      return;
    }

    if (reason < 1) {
      setErrorMsg("reason must be > 1");
      return;
    }

    props.onSubmit({ reason, duration });
    setErrorMsg(null);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter')
      onSubmit()
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Create a Recording</DialogTitle>
      <DialogContent>
        <DialogContentText>Select recording configuration</DialogContentText>
        <TextField
          className={styles.textInput}
          select
          variant="outlined"
          size="small"
          label="Reason"
          onChange={event => onRecordingReasonChanged(event.target.value)}
          onKeyPress={handleKeyPress}
          value={recConfig.reason}
        >
          <MenuItem value="1">Test</MenuItem>
          <MenuItem value="2">Calibration</MenuItem>
          <MenuItem value="3">Manufacturing</MenuItem>
          <MenuItem value="4">Developer</MenuItem>
        </TextField>
        <TextField
          className={styles.textInput}
          variant="outlined"
          size="small"
          label="Duration"
          type="number"
          onChange={event => onRecordingDurationChanged(event.target.value)}
          onKeyPress={handleKeyPress}
          value={recConfig.duration}
        />
        {(!errorMsg) ?
          null :
          <Alert variant="filled" severity="error">{errorMsg}</Alert>
        }
      </DialogContent>
      <DialogActions>
          <Button onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button onClick={() => onSubmit()}>
            Start
          </Button>
        </DialogActions>
    </Dialog>
  )
}

export default RecordingDialog;
