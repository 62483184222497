import React from 'react';
import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import Main from './Main';

const styles = {
  App: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#33506f',
    minHeight: '100vh',
    overflowX: 'hidden', /* workaround for Grid negative margin limitation */
  },
  AppHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '10vh',
  },
  AppLogo: {
    height: '5vmin',
    pointerEvents: 'none',
  },
  AppContainer: {
    padding: '16px',
    maxWidth: '1600px',
  }
};

function standardizeError(err) {
  let res = {};

  if (err.msg) {
    res.msg = err.msg;
  } else if (err.message) {
    res.msg = err.message;
  } else {
    res.msg = JSON.stringify(err);
  }

  if (err.stack) {
    res.stack = err.stack;
  } else {
    res.stack = "";
  }

  return res;
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };

    this.onError = this.onError.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
  }

  static getDerivedStateFromError(err) {
    return { error: standardizeError(err) };
  }

  onError(err) {
    this.setState({ error: standardizeError(err) });
  }

  onErrorClose() {
    this.setState({ error: null });
  }

  render() {
    return (
      <div className={this.props.classes.App}>
        <Grid container className={this.props.classes.AppContainer} direction="column">
          <Grid item>
            <header className={this.props.classes.AppHeader}>
              <img
                src={process.env.PUBLIC_URL + 'casana-logo-V2.svg'}
                className={this.props.classes.AppLogo}
                alt="logo" />
            </header>
          </Grid>
          {(this.state.error) ?
            <Grid item>
              <Alert onClose={() => { this.onErrorClose(); }} variant="filled" severity="error">
                <AlertTitle>{this.state.error.msg}</AlertTitle>
                {this.state.error.stack}
              </Alert>
            </Grid> :
            null
          }
          <Grid item>
            <Main onError={this.onError} onErrorClose={this.onErrorClose} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(App);
