import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

function FactoryResetDialog(props) {
  return (
    <Dialog open={props.open}>
      <DialogTitle>Factory Reset</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will delete all saved configs and recordings. Cloud configs will
          be re-synced from the cloud after reconnecting to the internet.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button onClick={() => props.onSubmit()}>
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FactoryResetDialog;
