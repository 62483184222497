import { makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    boxSizing: 'border-box',
    padding: '16px',
  },
}));

function Config(props) {
  const styles = useStyles();

  function getInputType(key, value) {
    if (key === 'wifi_pass') return 'password';
    else if (typeof value === 'number') return 'number';
    return 'any';
  }

  function getDisplayString(key, value) {
    if (key === 'wifi_pass' && value !== '') return '*****';

    if (typeof value === 'number' && value % 1 !== 0) {
      return value.toFixed(2).toString()
    }

    return value.toString();
  }

  return (
    <Paper className={styles.paper}>
      <Typography variant="h4" align="left">Configuration</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="30%" align="left">Name</TableCell>
            <TableCell align="left">Value</TableCell>
            <TableCell align="left">New Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(props.config).map(kv => (
            <TableRow key={kv[0]}>
              <TableCell>{kv[0]}</TableCell>
              <TableCell>{getDisplayString(kv[0], kv[1])}</TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  size="small"
                  onChange={event => props.onConfigChanged(kv[0], event.target.value)}
                  onWheel={event => event.target.blur()}
                  type={getInputType(kv[0], kv[1])}
                  color={props.configChanges[kv[0]] === undefined ? 'primary' : 'secondary'}
                  value={props.configChanges[kv[0]] === undefined ? '' : props.configChanges[kv[0]]} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default Config;
